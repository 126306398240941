// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-field_container__Yd5pX {
  overflow: hidden;
  border-radius: 0.75rem;
  background-color: rgba(var(--color-primary-900), 0.5);
  --tw-text-opacity: 1;
  color: rgba(var(--color-secondary-600), var(--tw-text-opacity));
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  border-width: 1px;
  border-color: rgb(255 255 255 / 0.15);
  transition: border 0.15s ease, box-shadow 0.15s ease
}

.input-field_input__xZyyV {
  width: 100%;
  background-color: transparent;
  outline: 2px solid transparent;
  outline-offset: 2px
}

.input-field_disabled__MRmIJ {
  background-color: rgb(255 255 255 / 0.05)
}

.input-field_input__xZyyV:disabled {
  background-color: transparent
}

.input-field_input__xZyyV::placeholder {
  color: rgb(255 255 255 / 0.4)
}

.input-field_container__Yd5pX:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(var(--accent-100), var(--tw-border-opacity));
  box-shadow: 4px 4px 0px rgba(var(--accent-100), 0.4)
}

.input-field_input__xZyyV::placeholder {
  opacity: 1
}

.input-field_error__VoT7y {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgba(var(--color-danger-600), var(--tw-text-opacity))
}`, "",{"version":3,"sources":["webpack://./src/components/input-field/index.module.scss"],"names":[],"mappings":"AACE;EAAA,gBAAA;EAAA,sBAAA;EAAA,qDAAA;EAAA,oBAAA;EAAA,+DAAA;EACA,aAAA;EAAA,mBAAA;EAAA,WAAA;EAAA,kBAAA;EAAA,mBAAA;EAAA,qBAAA;EAAA,wBAAA;EACA,iBAAA;EAAA,qCAAA;EACA;AAHA;;AAOA;EAAA,WAAA;EAAA,6BAAA;EAAA,8BAAA;EAAA;AAAA;;AAIA;EAAA;AAAA;;AAIA;EAAA;AAAA;;AAIA;EAAA;AAAA;;AAIA;EAAA,sBAAA;EAAA,+DAAA;EACA;AADA;;AAKA;EAAA;AAAA;;AAIA;EAAA,qBAAA;EAAA,sBAAA;EACA,mBAAA;EAAA,oBAAA;EAAA,oBAAA;EAAA;AADA","sourcesContent":[".container {\n  @apply bg-primary-900/50 text-secondary-600 rounded-xl overflow-hidden;\n  @apply flex items-center gap-2 px-4 py-2.5;\n  @apply border border-white/15;\n  transition: border .15s ease, box-shadow .15s ease;\n}\n\n.input {\n  @apply w-full bg-transparent outline-none;\n}\n\n.disabled {\n  @apply bg-white/5;\n}\n\n.input:disabled {\n  @apply bg-transparent;\n}\n\n.input::placeholder {\n  @apply text-white/40;\n}\n\n.container:focus-within {\n  @apply border-accent-100;\n  box-shadow: 4px 4px 0px rgba(var(--accent-100), 0.4);\n}\n\n.input::placeholder {\n  @apply opacity-100;\n}\n\n.error {\n  @apply px-1;\n  @apply text-danger-600 text-sm;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `input-field_container__Yd5pX`,
	"input": `input-field_input__xZyyV`,
	"disabled": `input-field_disabled__MRmIJ`,
	"error": `input-field_error__VoT7y`
};
export default ___CSS_LOADER_EXPORT___;
