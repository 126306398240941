// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button_button__ktA4d {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 700;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
}

.button_button__ktA4d:hover {
  opacity: 0.9;
  box-shadow: 0 0 6px 4px rgba(var(--accent-100), 0.4);
}

.button_button__ktA4d:active {
  transform: scale(0.96);
}

.button_button__ktA4d:disabled {
  pointer-events: none;
  opacity: 0.4;
}

.button_button__ktA4d:disabled.button_error__UEb6C {
  --tw-bg-opacity: 1;
  background-color: rgba(var(--color-danger-600), var(--tw-bg-opacity));
}

.button_spinner_btn__AwPyW {
  margin-right: 0.5rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.button_primary__vau7k {
  background-color: rgba(var(--accent-100), 50%);
  --tw-text-opacity: 1;
  color: rgba(var(--color-primary-900), var(--tw-text-opacity));
  border: 1px solid rgba(var(--accent-100), 0.15);
}

.button_primary_inverse__b9ZqU {
  border: 1px solid var(--color-button-primary-bg);
  color: var(--color-button-primary-bg);
}

.button_secondary__nee2q {
  --tw-bg-opacity: 1;
  background-color: rgba(var(--accent-100), var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.button_secondary_inverse__EnvZp {
  --tw-text-opacity: 1;
  color: rgba(var(--accent-100), var(--tw-text-opacity));
  border: 0;
}

.button_tertiary__dj8CF {
  background: var(--color-button-tertiary-bg);
  color: var(--color-button-tertiary);
}

.button_tertiary_inverse__753hs {
  border: 1px solid var(--color-button-tertiary-bg);
  color: var(--color-button-tertiary-bg);
}`, "",{"version":3,"sources":["webpack://./src/components/button/index.module.scss"],"names":[],"mappings":"AACE;EAAA,aAAA;EAAA,mBAAA;EAAA,uBAAA;EACA,mBAAA;EAAA,qBAAA;EAAA,sBAAA;EAAA,oBAAA;EAAA,uBAAA;EAAA,mBAAA;EAAA,oBAAA;EAAA,gBAAA;EAAA,wBAAA;EAAA,wDAAA;EAAA,0BAAA;EACA,mCAAA;UAAA;AAFA;;AAKF;EACE,YAAA;EACA,oDAAA;AACF;;AAEA;EACE,sBAAA;AACF;;AAEA;EACE,oBAAA;EACA,YAAA;AACF;;AAGE;EAAA,kBAAA;EAAA;AAAA;;AAIA;EAAA,oBAAA;EAAA,kBAAA;EAAA;AAAA;;AAIA;EAAA,8CAAA;EAAA,oBAAA;EAAA,6DAAA;EACA;AADA;;AAIF;EACE,gDAAA;EACA,qCAAA;AACF;;AAGE;EAAA,kBAAA;EAAA,+DAAA;EAAA,oBAAA;EAAA;AAAA;;AAIA;EAAA,oBAAA;EAAA,sDAAA;EACA;AADA;;AAIF;EACE,2CAAA;EACA,mCAAA;AACF;;AAEA;EACE,iDAAA;EACA,sCAAA;AACF","sourcesContent":[".button {\n  @apply flex items-center justify-center;\n  @apply px-3 py-3 rounded-[12px] text-sm font-bold transition-all;\n  backdrop-filter: blur(10px);\n}\n\n.button:hover {\n  opacity: .9;\n  box-shadow: 0 0 6px 4px rgba(var(--accent-100), 0.4);\n}\n\n.button:active {\n  transform: scale(0.96);\n}\n\n.button:disabled {\n  pointer-events: none;\n  @apply opacity-40;\n}\n\n.button:disabled.error {\n  @apply bg-danger-600;\n}\n\n.spinner_btn {\n  @apply text-xl mr-2;\n}\n\n.primary {\n  @apply text-primary-900 bg-accent-100/[50%];\n  border: 1px solid rgba(var(--accent-100), 0.15);\n}\n\n.primary_inverse {\n  border: 1px solid var(--color-button-primary-bg);\n  color: var(--color-button-primary-bg);\n}\n\n.secondary {\n  @apply bg-accent-100 text-white;\n}\n\n.secondary_inverse {\n  @apply text-accent-100;\n  border: 0;\n}\n\n.tertiary {\n  background: var(--color-button-tertiary-bg);\n  color: var(--color-button-tertiary);\n}\n\n.tertiary_inverse {\n  border: 1px solid var(--color-button-tertiary-bg);\n  color: var(--color-button-tertiary-bg);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `button_button__ktA4d`,
	"error": `button_error__UEb6C`,
	"spinner_btn": `button_spinner_btn__AwPyW`,
	"primary": `button_primary__vau7k`,
	"primary_inverse": `button_primary_inverse__b9ZqU`,
	"secondary": `button_secondary__nee2q`,
	"secondary_inverse": `button_secondary_inverse__EnvZp`,
	"tertiary": `button_tertiary__dj8CF`,
	"tertiary_inverse": `button_tertiary_inverse__753hs`
};
export default ___CSS_LOADER_EXPORT___;
