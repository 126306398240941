import { observer, useLocalObservable } from "mobx-react-lite";
import { useEffect } from "react";
import { createContext, useContext, useMemo, useState } from "react";
import { BlockStore } from "../stores/BlockStore";

type BaseStores = {
  blockStore: BlockStore;
};

const baseStoreContext = createContext<BaseStores>({} as BaseStores);

export const ProvideBaseStores = observer(({ children }: any) => {
  const blocks = useLocalObservable(() => new BlockStore());

  const stores = useMemo(
    () => ({
      blockStore: blocks
    }),
    [blocks]
  );

  useEffect(() => {
    // blocks.loadBlocks(
    //   "24cca8510832be8912656b641a45969d23cae04043f006836edd652d461ebc11",
    //   "136e963b6ff5b35ca89862f9aa990d22ef0ed443723004289e4b4e92af95b099"
    // );
  }, []);

  return (
    <baseStoreContext.Provider value={stores}>
      {children}
    </baseStoreContext.Provider>
  );
});

export const useBlockStore = () => {
  const { blockStore } = useContext(baseStoreContext);

  return blockStore;
};
