// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
.loader {
  animation: spin 1s ease-in-out infinite alternate both;
  height: 100px;
  position: relative;
  width: 100px;
}
.loader svg:not(#circle-middle) {
  animation: spin 1s ease-in-out infinite alternate both;
  position: absolute;
  top: -50%;
  left: 0;
  transform-origin: bottom center;
}

.loader svg:nth-of-type(1):not(#circle-middle) {
  transform: rotate(30deg);
}

.loader svg:nth-of-type(2):not(#circle-middle) {
  transform: rotate(60deg);
}

.loader svg:nth-of-type(3):not(#circle-middle) {
  transform: rotate(90deg);
}

.loader svg:nth-of-type(4):not(#circle-middle) {
  transform: rotate(120deg);
}

.loader svg:nth-of-type(5):not(#circle-middle) {
  transform: rotate(150deg);
}

.loader svg:nth-of-type(6):not(#circle-middle) {
  transform: rotate(180deg);
}

.loader svg:nth-of-type(7):not(#circle-middle) {
  transform: rotate(210deg);
}

.loader svg:nth-of-type(8):not(#circle-middle) {
  transform: rotate(240deg);
}

.loader svg:nth-of-type(9):not(#circle-middle) {
  transform: rotate(270deg);
}

.loader svg:nth-of-type(10):not(#circle-middle) {
  transform: rotate(300deg);
}

.loader svg:nth-of-type(11):not(#circle-middle) {
  transform: rotate(330deg);
}

.loader svg:nth-of-type(12):not(#circle-middle) {
  transform: rotate(360deg);
}

.loader svg:nth-of-type(13):not(#circle-middle) {
  transform: rotate(390deg);
}`, "",{"version":3,"sources":["webpack://./src/components/preloader/index.scss"],"names":[],"mappings":"AAeA;EACE;IACE,yBAAA;EAdF;AACF;AAiBA;EACE,sDAAA;EACA,aAtBkB;EAuBlB,kBAAA;EACA,YAxBkB;AASpB;AAiBE;EACE,sDAAA;EACA,kBAAA;EACA,SAAA;EACA,OAAA;EACA,+BAAA;AAfJ;;AAoBE;EACE,wBAAA;AAjBJ;;AAgBE;EACE,wBAAA;AAbJ;;AAYE;EACE,wBAAA;AATJ;;AAQE;EACE,yBAAA;AALJ;;AAIE;EACE,yBAAA;AADJ;;AAAE;EACE,yBAAA;AAGJ;;AAJE;EACE,yBAAA;AAOJ;;AARE;EACE,yBAAA;AAWJ;;AAZE;EACE,yBAAA;AAeJ;;AAhBE;EACE,yBAAA;AAmBJ;;AApBE;EACE,yBAAA;AAuBJ;;AAxBE;EACE,yBAAA;AA2BJ;;AA5BE;EACE,yBAAA;AA+BJ","sourcesContent":["$circle-count: 13;\n$loader-proportion: 100px;\n$animation_config: (\n  name: spin,\n  duration: 1s,\n  timing: ease-in-out,\n  iteration: infinite,\n  direction: alternate,\n  fill-mode: both\n);\n\n@function sh-setup($config) {\n  @return zip(map-values($config)...);\n}\n\n@keyframes spin {\n  to {\n    transform: rotate(360deg);\n  }\n}\n\n.loader {\n  animation: sh-setup($animation_config);\n  height: $loader-proportion;\n  position: relative;\n  width: $loader-proportion;\n\n  svg:not(#circle-middle) {\n    animation: sh-setup($animation_config);\n    position: absolute;\n    top: -50%;\n    left: 0;\n    transform-origin: bottom center;\n  }\n}\n\n@for $i from 1 through $circle-count {\n  .loader svg:nth-of-type(#{$i}):not(#circle-middle) {\n    transform: rotate(($i * (360/($circle-count - 1))) + deg);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
