/* eslint-disable no-var */
import React, { useEffect, useState } from "react";
import "./index.scss";
import Logo from "../../assets/img/logo192.png";
import { observer } from "mobx-react-lite";
import {
  ProvideBaseStores,
  useBlockStore
} from "../../providers/BaseStoresProvider";
import LoaderDots from "../../components/preloader";
import InputField from "../../components/input-field";
import Checkbox from "../../components/checkbox";
import Button from "../../components/button";
import copy from "copy-to-clipboard";
import { cloneDeep } from "lodash";

import Graph from "react-vis-network-graph";

const BLOCKS_TO_LOAD = 8;

const RenderApp: React.FC = observer(() => {
  const blockStore = useBlockStore();

  const [highlightActive, setHighlight] = useState<boolean>(false);
  const [leftBlockInput, setLeftBlock] = useState<string>("");
  const [leftBlockSeqno, setLeftBlockSeqno] = useState<string>("");
  const [graphNetwork, setGraphNetwork] = useState<any | null>(null);
  const [graphData, setGraphData] = useState<Record<string, any[]>>({
    nodes: [],
    edges: []
  });

  const getBlockX = (block: any) => {
    return block?.unixtime;
  };

  const getMinX = (blocks: any[]) => {
    const arr: number[] = blocks.map((_: any) => _?.unixtime);

    const minimum = Math.min(...arr);

    return minimum ? minimum : -1;
  };

  const getPosY = (block: any) => {
    if (block?.is_master) return 0;
    const number = BigInt(parseInt(block?.shard, 16));
    return +((number >> BigInt(56)) * BigInt(16)).toString();
  };

  const getColor = (block: any) => {
    if (block?.is_master) return "#008001";
    if (block?.before_split) return "Red";
    if (block?.after_merge) return "Fuchsia";
    if (block?.want_split) return "Orange";
    return "#97c2fc";
  };

  const drawGraph = (plot_data: any) => {
    const min_x = getMinX(plot_data?.all_blocks);
    let edges: any[] = [];
    let nodes: any[] = [];

    // plot_data["master_blocks"]
    plot_data?.master_blocks?.forEach((block: any) => {
      nodes.push({
        x: getBlockX(block) - min_x,
        y: getPosY(block),
        color: getColor(block),
        rootHash: block.rootHash,
        shape: "dot",
        title: `Master: seqno ${block.seqno}`,
        label: block.seqno,
        id: block?.seqno
      });
    });

    // plot_data["shard_blocks"]
    plot_data?.shard_blocks.forEach((block: any) => {
      if (block?.is_master) return;
      const colorData = blockStore.genSeqByColor.find(
        (_) => _?.num === block?.catchain_seqno
      );

      const obj = {
        x: getBlockX(block) - min_x,
        y: getPosY(block),
        color: colorData?.color || getColor(block),
        shape: "dot",
        title: `Shard: seqno ${block.seqno}`,
        label: `${block.shard.slice(0, 2)}:${block.seqno}:${
          block.catchain_seqno
        }`,
        id: block?.seqno
      };

      nodes.push(obj);
    });

    // plot_data["master_to_shard_link"]
    plot_data?.master_to_shard_link.forEach(
      (fromToArr: string[], key: number) => {
        const from = blockStore.allBlocks.find(
          (_: any) => fromToArr[0] === _?.rootHash
        );
        const to = blockStore.allBlocks.find(
          (_: any) => fromToArr[1] === _?.rootHash
        );

        if (!from || !to) return;

        edges?.push({
          from: from?.seqno,
          to: to?.seqno
        });
      }
    );

    // plot_data["shard_to_master_link"]
    plot_data?.shard_to_master_link.forEach((fromToArr: string[]) => {
      const from = blockStore.allBlocks.find(
        (_: any) => fromToArr[0] === _?.rootHash
      );
      const to = blockStore.allBlocks.find(
        (_: any) => fromToArr[1] === _?.rootHash
      );

      if (!from || !to) return;

      edges?.push({
        from: from?.seqno,
        to: to?.seqno
      });
    });

    // plot_data["master_to_master_link"]
    plot_data?.master_to_master_link.forEach((fromToArr: string[]) => {
      const from = blockStore.allBlocks.find(
        (_: any) => fromToArr[0] === _?.rootHash
      );
      const to = blockStore.allBlocks.find(
        (_: any) => fromToArr[1] === _?.rootHash
      );

      if (!from || !to) return;

      edges?.push({
        from: from?.seqno,
        to: to?.seqno
      });
    });

    // plot_data["master_to_master_link"]
    plot_data?.shard_to_shard_link.forEach((fromToArr: string[]) => {
      const from = blockStore.allBlocks.find(
        (_: any) => fromToArr[0] === _?.rootHash
      );
      const to = blockStore.allBlocks.find(
        (_: any) => fromToArr[1] === _?.rootHash
      );

      if (!from || !to) return;

      edges?.push({
        from: from?.seqno,
        to: to?.seqno
      });
    });

    setGraphData({
      edges,
      nodes
    });

    // { id: 22, label: "Node 2", color: "#e04141", x: Date.now() + 1, y: 0 }
  };

  const neighbourhoodHighlight = (params: any) => {
    const nodesBySeq = graphData.nodes.map((_) => _.id);
    const graphDataCopy = cloneDeep(graphData);
    // if something is selected:
    if (params.nodes.length > 0) {
      setHighlight(true);
      let i, j;
      let selectedNode = params.nodes[0];
      let degrees = 2;

      // mark all nodes as hard to read.
      for (var nodeId in graphDataCopy.nodes) {
        graphDataCopy.nodes[nodeId].color = "rgba(200,200,200,0.5)";
        if (graphDataCopy.nodes[nodeId].hiddenLabel === undefined) {
          graphDataCopy.nodes[nodeId].hiddenLabel =
            graphDataCopy.nodes[nodeId].label;
          graphDataCopy.nodes[nodeId].label = undefined;
        }
      }
      let connectedNodes = graphNetwork.getConnectedNodes(selectedNode);
      let allConnectedNodes: any[] = [];

      // get the second degree nodes
      for (i = 1; i < degrees; i++) {
        for (j = 0; j < connectedNodes.length; j++) {
          allConnectedNodes = allConnectedNodes.concat(
            graphNetwork.getConnectedNodes(connectedNodes[j])
          );
        }
      }

      // all second degree nodes get a different color and their label back
      for (i = 0; i < allConnectedNodes.length; i++) {
        const indexOfNode = nodesBySeq.indexOf(allConnectedNodes[i]);
        graphDataCopy.nodes[indexOfNode].color = "rgba(150,150,150,0.75)";
        if (graphDataCopy.nodes[indexOfNode].hiddenLabel !== undefined) {
          graphDataCopy.nodes[indexOfNode].label =
            graphDataCopy.nodes[indexOfNode].hiddenLabel;
          graphDataCopy.nodes[indexOfNode].hiddenLabel = undefined;
        }
      }

      // all first degree nodes get their own color and their label back
      for (i = 0; i < connectedNodes.length; i++) {
        const indexOfNode = nodesBySeq.indexOf(connectedNodes[i]);
        graphDataCopy.nodes[indexOfNode].color = undefined;
        if (graphDataCopy.nodes[indexOfNode].hiddenLabel !== undefined) {
          graphDataCopy.nodes[indexOfNode].label =
            graphDataCopy.nodes[indexOfNode].hiddenLabel;
          graphDataCopy.nodes[indexOfNode].hiddenLabel = undefined;
        }
      }

      const indexOfSelectedNode = nodesBySeq.indexOf(selectedNode);
      // the main node gets its own color and its label back.
      graphDataCopy.nodes[indexOfSelectedNode].color = undefined;
      if (graphDataCopy.nodes[indexOfSelectedNode].hiddenLabel !== undefined) {
        graphDataCopy.nodes[indexOfSelectedNode].label =
          graphDataCopy.nodes[indexOfSelectedNode].hiddenLabel;
        graphDataCopy.nodes[indexOfSelectedNode].hiddenLabel = undefined;
      }
    } else if (highlightActive === true) {
      // reset all nodes
      for (var nodeId in graphDataCopy.nodes) {
        graphDataCopy.nodes[nodeId].color = undefined;
        if (graphDataCopy.nodes[nodeId].hiddenLabel !== undefined) {
          graphDataCopy.nodes[nodeId].label =
            graphDataCopy.nodes[nodeId].hiddenLabel;
          graphDataCopy.nodes[nodeId].hiddenLabel = undefined;
        }
      }
      let updateArray: any[] = [];
      for (nodeId in graphDataCopy.nodes) {
        if (graphDataCopy.nodes.hasOwnProperty(nodeId)) {
          updateArray.push(graphDataCopy.nodes[nodeId]);
        }
      }
      const ids = updateArray.map((_: any) => _.id);

      graphNetwork.nodesHandler.update(ids, updateArray, graphData.nodes);
      setHighlight(false);
    }

    // transform the object into an array
    let updateArray: any[] = [];
    for (nodeId in graphDataCopy.nodes) {
      if (graphDataCopy.nodes.hasOwnProperty(nodeId)) {
        updateArray.push(graphDataCopy.nodes[nodeId]);
      }
    }

    const ids = updateArray.map((_: any) => _.id);
    graphNetwork.nodesHandler.update(ids, graphData.nodes, graphData.nodes);

    graphNetwork.nodesHandler.update(ids, updateArray, graphData.nodes);
  };

  // to draw new loaded blocks
  useEffect(() => {
    if (!blockStore?.loadingExtra && blockStore?.initialized) {
      drawGraph(blockStore?.nodesObj);
    }
  }, [blockStore.loadingExtra, blockStore?.initialized]);

  // to draw first blocks
  useEffect(() => {
    if (blockStore?.initialized) drawGraph(blockStore?.nodesObj);
  }, [blockStore.initialized]);

  useEffect(() => {
    if (blockStore?.initialized && graphNetwork && !highlightActive) {
      setTimeout(() => {
        console.log(graphNetwork, "graphNetwork");
        graphNetwork.fit();
      }, 2000);

      console.log("All blocks:", blockStore.allBlocks.length);
      console.log("Master blocks:", blockStore.nodesObj.master_blocks.length);
      console.log("Shard blocks:", blockStore.nodesObj.shard_blocks.length);
      console.log(
        "Master to shard link:",
        blockStore.nodesObj.master_to_shard_link.length
      );
      console.log(
        "Shard to master link:",
        blockStore.nodesObj.master_to_master_link.length
      );
      console.log(
        "Shard to shard link:",
        blockStore.nodesObj.shard_to_shard_link.length
      );
    }
  }, [graphNetwork, blockStore?.initialized]);

  const options = {
    configure: {
      enabled: false
    },
    edges: {
      color: {
        inherit: true
      },
      smooth: {
        enabled: true,
        type: "dynamic"
      }
    },
    interaction: {
      dragNodes: true,
      hideEdgesOnDrag: false,
      hideNodesOnDrag: false
    },
    physics: {
      enabled: false,
      stabilization: {
        enabled: true,
        fit: true,
        iterations: 1000,
        onlyDynamicEdges: false,
        updateInterval: 50
      }
    },
    height: "500px"
  };

  const selectLastBlock = async () => {
    const leftBlockMaster = await blockStore.loadBlockById(leftBlockInput);
    neighbourhoodHighlight({
      nodes: [leftBlockMaster?.seqno]
    });
    setHighlight(true);
  };

  const events = {
    select: async function (event: any) {
      let { nodes } = event;
      console.log(event, "event");
      neighbourhoodHighlight(event);
      const leftBlock = blockStore.allBlocks.find((_) => _?.seqno === nodes[0]);

      if (!leftBlock) return;

      const leftBlockId = leftBlock?.is_master
        ? leftBlock?.rootHash
        : leftBlock?.link_master;

      const leftBlockMaster = await blockStore.loadBlockById(leftBlockId);

      if (blockStore.loadingExtra || !leftBlockMaster) return;

      setLeftBlock(leftBlockMaster.rootHash);
      setLeftBlockSeqno(leftBlockMaster.seqno.toString());
      copy(leftBlock?.rootHash);
      // alert("copied");

      // const rightBlock = await blockStore.loadSingleBlock(
      //   leftBlockMaster?.seqno + 5,
      //   leftBlockMaster.shard,
      //   leftBlockMaster.workchain
      // );

      // blockStore.setLoadingExtra(true);
      // blockStore.loadBlocks(leftBlockId, rightBlock?.rootHash);
    }
  };

  const startLoadBlocks = async () => {
    console.log("startLoadBlocks");
    const leftBlockData = await blockStore.loadBlockById(leftBlockInput);
    if (!leftBlockData) return;

    const leftBlockId = leftBlockData?.is_master
      ? leftBlockData?.rootHash
      : leftBlockData?.link_master;

    const leftBlockMaster = await blockStore.loadBlockById(leftBlockId);

    if (!leftBlockMaster) return;

    const rightBlock = await blockStore.loadSingleBlock(
      leftBlockMaster?.seqno + BLOCKS_TO_LOAD,
      leftBlockMaster.shard,
      leftBlockMaster.workchain
    );

    blockStore.setLoadingExtra(true);
    blockStore.loadBlocks(leftBlockMaster?.rootHash, rightBlock?.rootHash);
    setLeftBlockSeqno(leftBlockMaster?.seqno.toString());
  };

  return (
    <div className="App">
      <header className="App-header">
        <img src={Logo} className="App-logo" alt="logos" />
        <div style={{ color: "white " }}>
          Initialized: {JSON.stringify(blockStore.initialized)}
        </div>
      </header>

      <div className="page">
        <form
          className="form-main"
          onSubmit={(e) => {
            e.preventDefault();
            startLoadBlocks();
          }}
        >
          <div className="w-10/12">
            <div className="flex items-center mb-4">
              <h2 className="text-base font-bold text-left mr-6">
                Venom network
              </h2>
              <Checkbox
                checked={blockStore.isVenom}
                onChange={(val) => blockStore.toggleNetwork(val)}
              />
            </div>
            <div>
              <h2 className="text-base font-bold text-left mb-2">
                Left block {leftBlockSeqno && <>({leftBlockSeqno})</>}
              </h2>
              <InputField
                id="left"
                name="left"
                type="text"
                onChange={(e) => setLeftBlock(e.target.value)}
                value={leftBlockInput}
                placeholder="Left block input"
              />
            </div>
          </div>
          <div className="flex ml-8">
            <Button variant="primary" type="submit" className="min-w-[100px]">
              Go
            </Button>
            <Button
              type="button"
              variant="secondary"
              disabled={!graphNetwork}
              className="ml-2 max-w-[160px]"
              onClick={() => {
                blockStore.cleanStore();
                setGraphData({
                  nodes: [],
                  edges: []
                });
                graphNetwork.fit();
              }}
            >
              Clear
            </Button>
          </div>
        </form>

        {blockStore.initialized ? (
          <div className="graph-wrap">
            {blockStore.loadingExtra && (
              <div className="loader-wrap loader-wrap--extra">
                <div className="loader-wrap__flex">
                  <h2>Blocks loading: {blockStore.blocksLoaded}</h2>
                  <LoaderDots />
                </div>
              </div>
            )}

            <div className="select-reset">
              <Button
                type="button"
                variant="primary"
                disabled={!graphNetwork || !highlightActive}
                className="ml-2 max-w-[160px]"
                onClick={() => {
                  // drawGraph(blockStore?.nodesObj);
                  graphNetwork.setData(graphData);
                  setHighlight(false);
                }}
              >
                Reset selection
              </Button>
              <Button
                type="button"
                variant="primary"
                disabled={!graphNetwork}
                className="ml-2 max-w-[160px]"
                onClick={() => selectLastBlock()}
              >
                Focus Block
              </Button>
            </div>

            <Graph
              graph={graphData}
              options={options}
              events={events}
              getNetwork={(network: any) => {
                console.log(network, "NETWORK");
                setGraphNetwork(network);
              }}
            />
          </div>
        ) : (
          <div className="graph-wrap">
            {blockStore.loadingExtra && (
              <div className="loader-wrap loader-wrap--extra">
                <div className="loader-wrap__flex">
                  <h2>Blocks loading: {blockStore.blocksLoaded}</h2>
                  <LoaderDots />
                </div>
              </div>
            )}

            <h2 className="text-xl font-bold">Nothing to show</h2>
          </div>
        )}
      </div>
    </div>
  );
});

function App() {
  return (
    <ProvideBaseStores>
      <RenderApp />
    </ProvideBaseStores>
  );
}

export default observer(App);
