import axios from "axios";
import { makeAutoObservable, runInAction } from "mobx";

const BROXUS_URL = "https://api.venomscan.com/v1/blocks";
const VENOM_URL = "https://testnet-api.venomscan.com/v1/blocks";
// const url_message_tree = "https://testnet-api.venomscan.com/v1/messages/tree";

const blocksLoadingMax = 1000;

export class BlockStore {
  constructor() {
    makeAutoObservable(this);
  }

  public mergeBlocks = true;
  public isVenom = true;

  // store all batch of blocks
  public allBlocks: any[] = [];

  // this one for every batch of blocks loading
  public newBatchOfBlocks: any[] = [];
  public nodesObj: Record<string, any[]> = {
    all_blocks: [],
    master_blocks: [],
    shard_blocks: [],
    master_to_master_link: [],
    master_to_shard_link: [],
    shard_to_master_link: [],
    shard_to_shard_link: []
  };

  public genSeqByColor: Record<"num" | "color", string | number>[] = [];
  public initialized = false;
  public loadingExtra = false;
  public blocksLoaded = 0;

  get url_block() {
    return this.isVenom ? VENOM_URL : BROXUS_URL;
  }

  newBlock(
    id: any,
    unixtime: any,
    seqno: any,
    workchain: any,
    shard: any,
    links_shardblocks: string[],
    link_master: any,
    link_prev_block: any,
    prev_block_seqno: any,
    lt_start: any,
    before_split: any,
    after_merge: any,
    want_split: any,
    catchain_seqno: any
  ) {
    return {
      rootHash: id,
      unixtime,
      shardsInfo: links_shardblocks,
      is_master: workchain === -1,
      seqno,
      workchain,
      shard,
      link_master,
      link_prev_block,
      prev_block_seqno,
      lt_start,
      before_split,
      after_merge,
      want_split,
      catchain_seqno
    };
  }

  setLoadingExtra(val: boolean) {
    this.loadingExtra = val;
  }

  setMergeBlocks = (val: boolean) => {
    this.mergeBlocks = val;
  };

  toggleNetwork = (val: boolean) => {
    this.isVenom = val;
  };

  cleanStore = () => {
    console.log("CLEAR");
    this.nodesObj = {
      all_blocks: [],
      master_blocks: [],
      shard_blocks: [],
      master_to_master_link: [],
      master_to_shard_link: [],
      shard_to_master_link: [],
      shard_to_shard_link: []
    };
    this.initialized = false;
    this.allBlocks = [];
    this.newBatchOfBlocks = [];
  };

  async parseMasterblock(block: any) {
    const links_shardblocks: string[] = [];

    block?.shardsInfo?.forEach((_: any) => {
      links_shardblocks.push(_?.info?.rootHash);
    });

    return this.newBlock(
      block?.rootHash,
      block?.additionalInfo?.time_ms,
      block?.seqno,
      block?.workchain,
      block?.shard,
      links_shardblocks,
      block?.prev?.prev1,
      "",
      block?.prev?.prev1Seqno,
      block?.blockInfo?.startLt,
      block?.blockInfo?.hasOwnProperty("beforeSplit"),
      block?.blockInfo?.hasOwnProperty("afterMerge"),
      block?.blockInfo?.hasOwnProperty("wantSplit"),
      block?.blockInfo?.genCatchainSeqno
    );
  }

  async parseShardblock(block: any) {
    const links_shardblocks: string[] = [];

    block?.additionalInfo?.shard_blocks?.forEach((_: any) => {
      links_shardblocks.push(_?.root_hash);
    });

    return this.newBlock(
      block?.rootHash,
      block?.additionalInfo?.time_ms,
      block?.seqno,
      block?.workchain,
      block?.shard,
      links_shardblocks,
      block?.blockInfo?.masterRef?.rootHash,
      block?.prev?.prev1,
      block?.prev?.prev1Seqno,
      block?.blockInfo?.startLt,
      block?.blockInfo?.hasOwnProperty("beforeSplit"),
      block?.blockInfo?.hasOwnProperty("afterMerge"),
      block?.blockInfo?.hasOwnProperty("wantSplit"),
      block?.blockInfo?.genCatchainSeqno
    );
  }

  parseBlockResponce(block: any) {
    const isMaster = block?.workchain === -1;

    if (isMaster) {
      return this.parseMasterblock(block);
    } else {
      return this.parseShardblock(block);
    }
  }

  async loadBlockById(id: string) {
    try {
      const response = await axios.post(this.url_block, {
        kind: "ByHash",
        id
      });

      return this.parseBlockResponce(response?.data);
    } catch (err) {
      return null;
    }
  }

  async loadSingleBlock(seqno = 0, shard: string, workchain = -2) {
    try {
      const response = await axios.post(this.url_block, {
        kind: "BySeqno",
        seqno,
        shard,
        workchain
      });

      if (!response.data) return null;

      return this.parseBlockResponce(response.data);
    } catch (err) {
      return null;
    }
  }

  needUploadShardBlock(
    blocks: any[],
    left_shard_blocks: Record<string, number>
  ): [boolean, string | null] {
    const blocksById: string[] = blocks.map((_) => _.rootHash);
    let result: null | string = null;

    blocks.forEach((_) => {
      if (_.is_master) return;
      const leftShardBlock = left_shard_blocks[_.shard];

      if (leftShardBlock && leftShardBlock > _?.seqno) {
        return;
      }

      if (blocksById.includes(_?.link_prev_block)) {
        return;
      }

      result = _?.link_prev_block;
    });

    if (!result) {
      return [false, null];
    }

    return [true, result];
  }

  async loadBlocks(left: string, right: string) {
    console.log("Loading blocks: l/r");
    console.log(left);
    console.log(right);
    let blocks: any = [];
    let master_seqnums: number[] = [];
    const all_blocks_by_id: string[] = this.allBlocks.map((_) => _.rootHash);
    const master_blocks_id: string[] = [];
    const left_shard_blocks_id: string[] = [];
    const left_shard_blocks_seqno: Record<string, number> = {};
    const right_shard_blocks_id: string[] = [];

    const leftAndRight = await Promise.all(
      [left, right].map(async (_: string) => {
        return await this.loadBlockById(_);
      })
    );

    if (!leftAndRight[0] || !leftAndRight[1]) return;

    // upload all master blocks
    for (let i = leftAndRight[0]?.seqno; i <= leftAndRight[1]?.seqno; i++) {
      master_seqnums.push(i);
    }

    console.log(`Left: ${leftAndRight[0]?.seqno + 1}`);
    console.log(`Right: ${leftAndRight[1]?.seqno}`);
    console.log(`master seqnums: ${master_seqnums}`);

    if (master_seqnums.length > 100) {
      alert("too many master blocks");
      return;
    }

    await Promise.all(
      master_seqnums.map(async (_) => {
        const block = await this.loadSingleBlock(
          _,
          leftAndRight[0]?.shard,
          leftAndRight[0]?.workchain
        );
        // if (
        //   all_blocks_by_id.length > 0 &&
        //   all_blocks_by_id.includes(block?.rootHash)
        // ) {
        //   console.log(block?.seqno, "RETURNED");
        //   return;
        // }

        blocks.push(block);
        master_blocks_id.push(block?.rootHash);
      })
    );

    // upload left shard blocks
    await Promise.all(
      leftAndRight[0].shardsInfo?.map(async (_: string) => {
        const block: any = await this.loadBlockById(_);
        left_shard_blocks_id.push(block?.rootHash);
        Object.assign(left_shard_blocks_seqno, {
          ...left_shard_blocks_seqno,
          [block?.shard]: block?.seqno
        });
        blocks.push(block);
      })
    );

    // upload right shard blocks
    await Promise.all(
      leftAndRight[1]?.shardsInfo?.map(async (_: string) => {
        const block: any = await this.loadBlockById(_);
        right_shard_blocks_id.push(block?.rootHash);
        blocks.push(block);
      })
    );

    // upload all shard blocks for master blocks
    await Promise.all(
      master_blocks_id?.map(async (_: string) => {
        const blocksIds: string[] = blocks.map((bl: any) => bl?.rootHash);
        const block = blocks.find(
          (blockData: any) => _ === blockData?.rootHash
        );

        if (!block) return;

        block.shardsInfo.forEach(async (shardId: string) => {
          if (blocksIds.includes(shardId)) return;

          blocks.push(await this.loadBlockById(shardId));
        });
      })
    );

    const infiniteLoop = async () => {
      if (blocks.length % 10 === 0 && blocks?.length) {
        console.log("Loaded:", blocks.length, " blocks");
        runInAction(() => {
          this.blocksLoaded = blocks.length;
        });
      }

      const [need_upload, block_id] = this.needUploadShardBlock(
        blocks,
        left_shard_blocks_seqno
      );

      if (need_upload === false || blocks?.length > blocksLoadingMax) {
        console.log(left_shard_blocks_id, "left_shard_blocks_id");
        console.log(left_shard_blocks_seqno, "left_shard_blocks_seqno");
        console.log(right_shard_blocks_id, "right_shard_blocks_id");
        runInAction(() => {
          if (this.mergeBlocks) {
            this.allBlocks = [...this.allBlocks, ...blocks];
          } else {
            this.allBlocks = blocks;
          }

          this.newBatchOfBlocks = blocks;
        });
        this.buildPlotData();
        return;
      }

      if (block_id) {
        const block = await this.loadBlockById(block_id);
        blocks.push(block);
      }

      infiniteLoop();
    };

    infiniteLoop();
  }

  buildPlotData() {
    let plot_data = {
      all_blocks: this.allBlocks,
      master_blocks: [],
      shard_blocks: [],
      master_to_master_link: [],
      master_to_shard_link: [],
      shard_to_master_link: [],
      shard_to_shard_link: []
    } as any;

    if (this.mergeBlocks) {
      plot_data = {
        ...this.nodesObj,
        all_blocks: this.allBlocks
      };
    }

    console.log(plot_data, "plot_data");

    const allBlocksById = this.newBatchOfBlocks.map((_) => _?.rootHash);
    const genSeqno: number[] = [];
    const colors = ["#97c2fc", "red", "orange"];

    this.newBatchOfBlocks?.forEach((_: any) => {
      if (!genSeqno.includes(_.catchain_seqno)) {
        genSeqno.push(_.catchain_seqno);
      }

      if (_?.is_master) {
        plot_data.master_blocks.push(_);

        if (allBlocksById.includes(_.link_master)) {
          plot_data.master_to_master_link.push([_?.rootHash, _.link_master]);
        }

        _?.shardsInfo.forEach((shardId: string) => {
          if (allBlocksById.includes(shardId)) {
            plot_data.master_to_shard_link.push([_?.rootHash, shardId]);
          }
        });
      } else {
        if (!plot_data.shard_blocks.find((it: any) => it?.seqno === _.seqno)) {
          plot_data.shard_blocks.push(_);
        }

        if (allBlocksById.includes(_.link_master)) {
          plot_data.shard_to_master_link.push([_?.rootHash, _.link_master]);
        }

        _?.shardsInfo.forEach((shardId: string) => {
          if (allBlocksById.includes(shardId)) {
            plot_data.shard_to_shard_link.push([_?.rootHash, shardId]);
          }
        });
      }
    });

    const genSeqnoObj: Record<"num" | "color", string | number>[] =
      genSeqno.map((_, key) => {
        const colorIndex = key % colors.length;
        return {
          num: _,
          color: colors[colorIndex]
        };
      });

    runInAction(() => {
      this.nodesObj = plot_data;
      this.genSeqByColor = genSeqnoObj;
      this.initialized = true;
      this.loadingExtra = false;
      this.blocksLoaded = 0;
      console.log(plot_data, this.initialized, "ALL BLOCKS");
    });
  }
}
