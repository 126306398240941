// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkbox_input__xmarc {

    position: absolute;

    display: none
}

.checkbox_checkbox__BBgeO {

    border-radius: 4px;

    --tw-bg-opacity: 1;

    background-color: rgba(var(--color-secondary-200), var(--tw-bg-opacity));

    color: rgb(255 255 255 / var(--tw-text-opacity));

    --tw-text-opacity: 0;

    display: flex;

    align-items: center;

    justify-content: center;

    height: 1.25rem;

    width: 1.25rem;

    cursor: pointer;

    -webkit-user-select: none;

            user-select: none
}

.checkbox_disabled__2aqqU {

    pointer-events: none;

    opacity: 0.5
}

.checkbox_input__xmarc:checked ~ .checkbox_checkbox__BBgeO {

    --tw-bg-opacity: 1;

    background-color: rgba(var(--accent-100), var(--tw-bg-opacity));

    --tw-text-opacity: 1
}`, "",{"version":3,"sources":["webpack://./src/components/checkbox/index.module.scss"],"names":[],"mappings":"AACE;;IAAA,kBAAA;;IAAA;AAAA;;AAIA;;IAAA,kBAAA;;IAAA,kBAAA;;IAAA,wEAAA;;IAAA,gDAAA;;IAAA,oBAAA;;IACA,aAAA;;IAAA,mBAAA;;IAAA,uBAAA;;IACA,eAAA;;IAAA,cAAA;;IAAA,eAAA;;IAAA,yBAAA;;YAAA;AAFA;;AAMA;;IAAA,oBAAA;;IAAA;AAAA;;AAIA;;IAAA,kBAAA;;IAAA,+DAAA;;IAAA;AAAA","sourcesContent":[".input {\n  @apply absolute hidden;\n}\n\n.checkbox {\n  @apply text-opacity-0 text-white bg-secondary-200 rounded-[4px];\n  @apply flex justify-center items-center;\n  @apply w-5 h-5 select-none cursor-pointer;\n}\n\n.disabled {\n  @apply opacity-50 pointer-events-none;\n}\n\n.input:checked ~ .checkbox {\n  @apply text-opacity-100 bg-accent-100;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `checkbox_input__xmarc`,
	"checkbox": `checkbox_checkbox__BBgeO`,
	"disabled": `checkbox_disabled__2aqqU`
};
export default ___CSS_LOADER_EXPORT___;
